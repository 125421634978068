import React, { useState } from "react";
import axios from "axios";
import { Button, Heading, InputNumber, InputPicker, Stack } from "rsuite";
import calculate from "./calculate";

function CalculateElevationGridForm() {
    const [mortarEasting, setMortarEasting] = useState(0);
    const [mortarNorthing, setMortarNorthing] = useState(0);
    const [mortarHeight, setMortarHeight] = useState(0);
    const [observerToEnemyAzimuth, setObserverToEnemyAzimuth] = useState(0);
    const [enemyEasting, setEnemyEasting] = useState(0);
    const [enemyNorthing, setEnemyNorthing] = useState(0);
    const [enemyHeight, setEnemyHeight] = useState(0);
    const [artillery, setArtillery] = useState<keyof typeof calculate.artilleryMap>("m252");

    const [result, setResult] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const data = {
            mortarEasting: mortarEasting,
            mortarNorthing: mortarNorthing,
            mortarHeight: mortarHeight,
            observerToEnemyAzimuth: observerToEnemyAzimuth,
            enemyEasting: enemyEasting,
            enemyNorthing: enemyNorthing,
            enemyHeight: enemyHeight,
            artillery: artillery,
        };

        setLoading(true);
        try {
            // const response = await axios.post('/api/calculate_elevation_grid', data);
            // console.log(response)
            const response = calculate.calculateElevationGrid(data);
            console.log(response);
            setResult(response);
            // setResult(response.data);
            setError(null);
        } catch (err) {
            setError("Error calculating elevation.");
            setResult(null);
        } finally {
            setLoading(false);
        }
    };

    const artilleryOptions = [
        { label: "M252", value: "m252" },
        { label: "M119", value: "m119" },
        { label: "2S1", value: "t2s1" },
        { label: "t14", value: "t14" },
    ];

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={10} direction="column">
                    <Stack alignItems="flex-start" direction="column">
                        <label>Artillery</label>
                        <InputPicker
                            size="sm"
                            value={artillery}
                            data={artilleryOptions}
                            onChange={(value) => setArtillery(value)}
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Mortar Easting</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setMortarEasting(value as number)}
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Mortar Northing</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setMortarNorthing(value as number)}
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Mortar Height</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setMortarHeight(value as number)}
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Observer to Enemy Azimuth</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setObserverToEnemyAzimuth(value as number)}
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Enemy Easting</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setEnemyEasting(value as number)}
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Enemy Northing</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setEnemyNorthing(value as number)}
                            required
                        />
                    </Stack>
                    <Stack alignItems="flex-start" direction="column">
                        <label>Enemy Height</label>
                        <InputNumber
                            size="sm"
                            onChange={(value) => setEnemyHeight(value as number)}
                            required
                        />
                    </Stack>
                    <Button
                        type="submit"
                        appearance="primary"
                        disabled={loading}
                    >
                        {loading ? "Calculating..." : "Calculate"}
                    </Button>
                </Stack>
            </form>
            {result && (
                <div className="mt-4">
                    <h2>Result</h2>
                    <p>
                        <strong>Azimuth:</strong> {result.azimuth} degrees
                    </p>
                    <p>
                        <strong>Elevation:</strong> {result.elevation}{" "}
                        miliradians + offset
                    </p>
                    <p>
                        <strong>Time to Impact:</strong> {result.timeToImpact}{" "}
                        seconds
                    </p>
                    <p>
                        <strong>Max Ord:</strong> {result.maxOrd} m
                    </p>
                </div>
            )}
            {error && (
                <div className="alert alert-danger mt-4" role="alert">
                    {error}
                </div>
            )}
        </>
    );
}

export default CalculateElevationGridForm;
