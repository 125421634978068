import React, { useState } from "react";
import CalculateElevationPolarForm from "./CalculateElevationPolarForm";
import CalculateElevationGridForm from "./CalculateElevationGridForm";
import { CustomProvider, Heading } from "rsuite";
import { Button, InputPicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

document.addEventListener("wheel", function (event) {
    const activeEl = document.activeElement as HTMLInputElement;
    if (activeEl) {
        if (activeEl.type === "text" || activeEl.type === "number") {
            activeEl.blur();
        }
    }
});

function App() {
    const [form, setForm] = useState(null);
    const formOptions = [
        { label: "Polar w/ Observer", value: "CalculatePolarElevationForm" },
        {
            label: "Grid Coordinate to Grid Coordinate",
            value: "CalculateGridCoordForm",
        },
    ];

    let component = <></>;

    if (form == "CalculatePolarElevationForm") {
        component = <CalculateElevationPolarForm />;
    } else if (form == "CalculateGridCoordForm") {
        component = <CalculateElevationGridForm />;
    }

    return (
        <CustomProvider theme="dark">
            <Heading level={1} style={{ textAlign: "center" }}>
                WCS Artillery Calculator
            </Heading>
            <Stack alignItems="center" direction="column">
                <Stack alignItems="flex-start" direction="column">
                    <label>Calculator Type</label>
                    <InputPicker
                        size="sm"
                        data={formOptions}
                        onChange={(e) => setForm(e)}
                    />
                </Stack>
                {component}
            </Stack>
        </CustomProvider>
    );
}

export default App;
