import artilleryLib from "./artillery_lib";

// Map Artillery string to Artillery object
const artilleryMap = {
    m252: new artilleryLib.Artillery("m252", 0.25, 0.00031, 375, true),
    m119: new artilleryLib.Artillery("m119", 23, 0.0043, 212.5, false),
    t2s1: new artilleryLib.Artillery("t2s1", 21.76, 0.00647, 690, false),
    t14: new artilleryLib.Artillery("t14", 5.12, .0013, 1660, false)
};

class GridCalculationRequest {
    mortarEasting: number;
    mortarNorthing: number;
    mortarHeight: number;
    observerToEnemyAzimuth: number;
    enemyEasting: number;
    enemyNorthing: number;
    enemyHeight: number;
    artillery: keyof typeof artilleryMap
    constructor(
        mortarEasting: number,
        mortarNorthing: number,
        mortarHeight: number,
        observerToEnemyAzimuth: number,
        enemyEasting: number,
        enemyNorthing: number,
        enemyHeight: number,
        artillery: keyof typeof artilleryMap
    ) {
        this.mortarEasting = mortarEasting;
        this.mortarNorthing = mortarNorthing;
        this.mortarHeight = mortarHeight;
        this.observerToEnemyAzimuth = observerToEnemyAzimuth;
        this.enemyEasting = enemyEasting;
        this.enemyNorthing = enemyNorthing;
        this.enemyHeight = enemyHeight;
        this.artillery = artillery;
    }
}


class PolarCalculationRequest {
    mortarEasting: number;
    mortarNorthing: number;
    mortarHeight: number;
    observerEasting: number;
    observerNorthing: number;
    observerHeight: number;
    observerToEnemyAzimuth: number;
    observerToEnemyHorizontal: number;
    observerToEnemyVertical: number;
    artillery: keyof typeof artilleryMap

    constructor(
        mortarEasting: number,
        mortarNorthing: number,
        mortarHeight: number,
        observerEasting: number,
        observerNorthing: number,
        observerHeight: number,
        observerToEnemyAzimuth: number,
        observerToEnemyHorizontal: number,
        observerToEnemyVertical: number,
        artillery: keyof typeof artilleryMap
        ) {
        this.mortarEasting = mortarEasting;
        this.mortarNorthing = mortarNorthing;
        this.mortarHeight = mortarHeight;
        this.observerEasting = observerEasting;
        this.observerNorthing = observerNorthing;
        this.observerHeight = observerHeight;
        this.observerToEnemyAzimuth = observerToEnemyAzimuth;
        this.observerToEnemyHorizontal = observerToEnemyHorizontal;
        this.observerToEnemyVertical = observerToEnemyVertical;
        this.artillery = artillery;
    }
}

function calculateElevationPolar(request: PolarCalculationRequest) {
    const artillery = artilleryMap[request.artillery];
    if (!artillery) {
        return "Invalid artillery type.";
    }

    const mortarPos = new artilleryLib.GridCoord(
        request.mortarEasting,
        request.mortarNorthing
    );
    const observerPos = new artilleryLib.GridCoord(
        request.observerEasting,
        request.observerNorthing
    );
    const azimuth_result = artilleryLib.azimuthFromObserverRelative(
        mortarPos,
        observerPos,
        request.observerToEnemyAzimuth,
        request.observerToEnemyHorizontal
    );
    let mortarToEnemyAzimuth = azimuth_result[0];
    const mortarToEnemyDistance = azimuth_result[1];

    let elevationResult = artilleryLib.calculateElevation(
        artillery,
        request.observerHeight +
            request.observerToEnemyVertical -
            request.mortarHeight,
        mortarToEnemyDistance
    );

    if (request.artillery == "t2s1") {
        mortarToEnemyAzimuth = mortarToEnemyAzimuth / 360 * 6000;
        elevationResult.elevation = elevationResult.elevation / 1000 / (2 * Math.PI) * 6000
    }

    const result = {
        azimuth: Math.round(mortarToEnemyAzimuth * 100) / 100,
        elevation: Math.round(elevationResult.elevation),
        timeToImpact: elevationResult.timeToImpact,
        maxOrd: elevationResult.maxOrd,
    };

    if (typeof result === "string") {
        throw new Error(result);
    }

    return result;
}

function calculateElevationGrid(request: GridCalculationRequest) {
    const artillery = artilleryMap[request.artillery];
    if (!artillery) {
        return "Invalid artillery type.";
    }

    const mortar_pos = new artilleryLib.GridCoord(
        request.mortarEasting,
        request.mortarNorthing
    );
    const enemy_pos = new artilleryLib.GridCoord(
        request.enemyEasting,
        request.enemyNorthing
    );
    let mortarToEnemyAzimuth = artilleryLib.azimuthFromGrids(
        mortar_pos,
        enemy_pos
    );
    const mortarToEnemyHorizDistance = artilleryLib.distancefunc(
        mortar_pos.easting,
        mortar_pos.northing,
        0,
        enemy_pos.easting,
        enemy_pos.northing,
        0
    );

    let elevationResult = artilleryLib.calculateElevation(
        artillery,
        request.enemyHeight,
        mortarToEnemyHorizDistance
    );

    console.log("artillery type", request.artillery);
    if (request.artillery == "t2s1") {
        mortarToEnemyAzimuth = mortarToEnemyAzimuth / 360 * 6000;
        elevationResult.elevation = elevationResult.elevation / 1000 / (2 * Math.PI) * 6000
    }

    const result = {
        azimuth: Math.round(mortarToEnemyAzimuth * 100) / 100,
        elevation: Math.round(elevationResult.elevation),
        timeToImpact: elevationResult.timeToImpact,
        maxOrd: elevationResult.maxOrd,
    };

    if (typeof result === "string") {
        throw new Error(result);
    }
    return result;
}

const calculate = {
    artilleryMap,
    GridCalculationRequest,
    PolarCalculationRequest,
    calculateElevationGrid,
    calculateElevationPolar,
};
export default calculate;
